<template>
  <validation-observer ref="form" v-slot="{ invalid }">
    <layout width="420">
      <template slot="header">
        {{ title }}
      </template>

      <template>
        <v-form>
          <validation-provider
            v-slot="{ errors }"
            name="State name"
            rules="required"
          >
            <v-text-field
              v-model="state.name"
              label="State name"
              :error-messages="errors"
            >
              {{ state.name }}
            </v-text-field>
          </validation-provider>
          <validation-provider v-slot="{ errors }" name="Icon" rules="required">
            <form-icon-selector
              v-model="state.icon"
              label="Icon"
              :items="iconItems"
              :error-messages="errors"
            />
          </validation-provider>
          <form-color-selector v-model="state.color" label="Color" />
          <div v-if="showCondition" class="d-flex flex-nowrap">
            <v-select
              v-model="state.operator"
              :items="operators"
              class="mr-4"
            />
            <v-text-field v-model="state.value" />
          </div>
        </v-form>
      </template>
      <template slot="footer">
        <v-spacer />
        <v-btn text color="text-primary" @click.stop="$emit('close')">
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          depressed
          :disabled="invalid"
          :loading="loading"
          @click.stop="submit"
        >
          {{ submitBtnTitle }}
        </v-btn>
      </template>
    </layout>
  </validation-observer>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import { ref, onMounted, computed } from '@vue/composition-api';
import { usePromise } from 'vue-composable';
import { iconsService, stateService } from '@/modules/object-types/api';
import { schemasService } from '@/modules/common/api';
import { ColorNames } from '@/compositions/map/utils/data';
import {
  getObjectImageUrlOrDefault,
  getSchemaPropertyByKey
} from '@/provider/utils';
import { abcSort } from '@/compositions/sortBy';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'This field is required'
});

export default {
  name: 'StateEdit',
  components: {
    Layout,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    parentSchemaId: {
      type: String,
      default: ''
    },
    stateId: {
      type: String,
      default: ''
    },
    parentType: {
      type: Object,
      default: null
    }
  },
  setup(props, { emit }) {
    const form = ref(null);
    const operators = ['>', '<', '=', '!=', 'contains'];
    const objectState = ref({});
    const state = ref({
      name: '',
      color: ColorNames.default,
      icon: '',
      operator: '',
      value: ''
    });
    const showCondition = ref(false);
    const edit = computed(() => props.stateId);
    const title = computed(() => (edit.value ? 'Edit state' : 'Add state'));
    const submitBtnTitle = computed(() => (edit.value ? 'Save' : 'Add'));
    const iconItems = ref([]);

    const handleSubmit = async () => {
      if (!(await form.value.validate())) return;
      let createdStateId = props.stateId;
      if (!edit.value) {
        // create new state

        const schemaId = await stateService.fetchBaseStateSchemaId();
        createdStateId = await stateService.create({
          name: state.value.name,
          schemaId,
          parentSchemaId: props.parentSchemaId
        });
        objectState.value = await stateService.fetch(createdStateId);
      } else {
        await stateService.update(props.stateId, {
          name: state.value.name
        });
        // edit new state
      }
      await schemasService.updateValues(
        [
          {
            id: objectState.value.infoName.id,
            value: state.value.name
          },
          {
            id: objectState.value.stateColor.id,
            value: state.value.color
          },
          {
            id: objectState.value.stateIcon.id,
            value: state.value.icon
          },
          {
            id: objectState.value.stateCondition.id,
            value: {
              operator: state.value.operator,
              value: state.value.value
            }
          }
        ],
        createdStateId
      );
      // if we have parentType that means we edit default state and we must set icon and color as default for parent type
      if (props.parentType) {
        const { id: propertyColorId } = getSchemaPropertyByKey(
          props.parentType,
          'currentStateColor'
        );
        const { id: propertyIconId } = getSchemaPropertyByKey(
          props.parentType,
          'currentStateIcon'
        );
        await schemasService.updateValues(
          [
            {
              id: propertyColorId,
              value: state.value.color
            },
            {
              id: propertyIconId,
              value: state.value.icon
            }
          ],
          props.parentType.id
        );
      }
      emit('close');
    };

    const { loading, exec: submit } = usePromise(handleSubmit, true);

    onMounted(async () => {
      const icons = await iconsService.fetch();
      iconItems.value = icons
        .map(i => ({
          ...i,
          src: getObjectImageUrlOrDefault(i.id)
        }))
        .sort((a, b) => abcSort(a.name, b.name));

      if (edit.value) {
        objectState.value = await stateService.fetch(props.stateId);
        showCondition.value = !objectState.value.stateIsDefault?.value;
        state.value = {
          name: objectState.value.infoName.value,
          color: objectState.value.stateColor.value || ColorNames.default,
          icon: objectState.value.stateIcon.value,
          operator: objectState.value.stateCondition?.value?.operator,
          value: objectState.value.stateCondition?.value?.value
        };
      }
    });

    return {
      state,
      loading,
      title,
      submitBtnTitle,
      submit,
      edit,
      iconItems,
      operators,
      showCondition,
      form
    };
  }
};
</script>
